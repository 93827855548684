import React from "react";
import { useHistory } from 'react-router-dom';
// views


export default function InstallSuccess() {
  const history = useHistory();
  

  React.useEffect(() => {
    const timer = setTimeout(() => {
      history.push('/team');
    }, 10000); // push after 3 seconds

    // Clean up function
    return () => {
      clearTimeout(timer);
    }
  }, [history]); // 


  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/felix_auth_bg.png").default + ")",
            }}
          >

          </div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-felixblue-400 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3">
                      <h6 className="text-white text-md font-bold">
                        Install Success!
                      </h6>
                      <h3 className="text-white text-sm font-bold">
                        We are so excited to have you here. Now start using Felix!
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}