import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import BlogCard from "components/Cards/BlogCard";
import { blog_contents } from "utilities/blogContent";


export default function Blog() {
  return (
    <>
      <main>
        <IndexNavbar fixed />
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/felix_auth_bg.png").default + ")",
            }}
          ></div>
          
          
          <div className="container mx-auto">
          
          <div className="flex items-center pb-4">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-4 md:px-4 -mt-32">
                <div className="relative flex flex-col min-w-0 break-words bg-felixblue-400 w-full mb-6 rounded-lg">
                    <blockquote className="relative p-2 mb-4">
                        <h2 className="text-xl font-bold text-white">{'Welcome to the Felix Blog'}</h2>
                    </blockquote>              
                </div>
            </div>
        </div>
          <div className="flex flex-wrap items-start py-20">
            <BlogCard 
              imageUrl={blog_contents[0].imageUrl}
              title={blog_contents[0].title}
              description={blog_contents[0].description}
              alt={blog_contents[0].alt}
              path={blog_contents[0].path}
            />
            <BlogCard 
              imageUrl={blog_contents[1].imageUrl}
              title={blog_contents[1].title}
              description={blog_contents[1].description}
              alt={blog_contents[1].alt}
              path={blog_contents[1].path}
            />

            <BlogCard 
              imageUrl={blog_contents[2].imageUrl}
              title={blog_contents[2].title}
              description={blog_contents[2].description}
              alt={blog_contents[2].alt}
              path={blog_contents[2].path}
            />

          </div>
        </div>
        </section>
      </main>
    </>
  );
}
