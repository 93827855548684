import React from "react";
import { apiKey } from "../utilities/helperFunctions.js"
import { useHistory } from 'react-router-dom';
// views


export default function PaymentSuccess() {
  const history = useHistory();
  const [customerEmail, setCustomerEmail] = React.useState('');


  React.useEffect(() => {
    if (customerEmail) {  // only if customerEmail is not null or undefined
      const timer = setTimeout(() => {
        history.push('/team');
      }, 3000); // push after 3 seconds

      // Clean up function
      return () => {
        clearTimeout(timer);
      }
    }
  }, [customerEmail, history]); // re-run the effect when customerEmail changes


  React.useEffect(() => {
    // Set the URL in state when the component mounts
    const sessionId = window.location.href.split('?session_id=')[1]
    if (sessionId) {
      fetch(`https://api.getfelix.app/stripe/order/success?sessionId=${sessionId}&apiKey=${apiKey}`, {
        method: 'GET',
      })
        .then(response => response.json())
        .then(data => {
          // Decode JWT
          setCustomerEmail(data.email)
        }
        )
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/felix_auth_bg.png").default + ")",
            }}
          >

          </div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-felixblue-400 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3">
                      <h6 className="text-white text-md font-bold">
                        Payment Success!
                      </h6>
                      <h3 className="text-white text-sm font-bold">
                        You will be redirected shortly!
                      </h3>
                      <h3 className="text-white text-xs mt-1">
                        {customerEmail ? `You should receive a confirmation email at ${customerEmail}` : ''}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}