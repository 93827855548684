import React from "react";

export default function Support() {
  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/felix_auth_bg.png").default + ")",
            }}
          ></div>
          
          <>
            <div className="container mx-auto px-4 h-full">
              <div className="flex content-center items-center justify-center h-full">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-felixblue-400 border-0">
                    <div className="rounded-t mb-0 px-6 py-6">
                      <div className="text-center mb-3">
                        <h6 className="text-white text-sm font-bold">
                          To get support for your Felix experience please email us at: team@getfelix.app
                        </h6>
                        <a 
                        href="https://slack.com/help/articles/221769328-Locate-your-Slack-URL"
                        className="w-full text-xs py-3 font-bold block text-white">
                        Please include your Slack workspace URL in your email.
                        </a>
                      </div>
                      {/* <div className="btn-wrapper text-center" dangerouslySetInnerHTML={{ __html: slackButtonHTML }} /> */}
                    </div>
                  </div>
                  <div className="flex flex-wrap mt-6 relative">
                  <div className="w-4/12 px-4">
                    <a
                        href="mailto:team@getfelix.app"
                        className="w-full text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-700"
                    >
                        <i className="fas fa-envelope mr-2 text-md text-blueGray-300"></i>
                        Click here to Email Us
                    </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </section>
      </main>
    </>
  );
}
