import { apiKey } from "../../utilities/helperFunctions.js"

const proId = 'price_1NB8wwG8F1uO3p65elgbV6fv'
const premiumId = 'price_1NBprdG8F1uO3p65cDKVdNLj'


export default function UpgradePlan(
  {teamId}
) {
    return (
        <>
        <div className="justify-left text-left flex flex-wrap mt-2">
        <div className="w-full md:w-6/12 px-12 md:px-4">
          <h2 className="font-semibold text-4xl">Upgrade</h2>
        </div>
      </div>

      <div className="flex flex-wrap justify-center">
        <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg overflow-hidden">
            <div className="px-4 py-5 flex-auto">
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-2 shadow-lg rounded-full bg-lightBlue-400">
                <i class="fas fa-running"></i>
              </div>
              <h6 className="text-xl font-semibold">Pro: $10 / mo</h6>
              <p className="mt-2 text-blueGray-500">
                50 calls per month across all users
              </p>
              <form action={`https://api.getfelix.app/stripe/create-checkout-session-pro?teamId=${teamId}&apiKey=${apiKey}`} method="POST">
                <input type="hidden" name="priceId" value={proId} />
                <button type="submit" className="bg-blueGray-200 hover:bg-blue-700 text-felixblue-400 font-bold py-2 px-4 rounded mt-1">
                  Checkout
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg overflow-hidden">
            <div className="px-4 py-5 flex-auto">
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-2 shadow-lg rounded-full bg-emerald-400">
                <i class="fas fa-glass-cheers"></i>
              </div>
              <h6 className="text-xl font-semibold">Premium: $25 / mo</h6>
              <p className="mt-2 text-blueGray-500">
                200 calls per month across all users
              </p>
              <form action={`https://api.getfelix.app/stripe/create-checkout-session-premium?teamId=${teamId}&apiKey=${apiKey}`} method="POST">
                <input type="hidden" name="priceId" value={premiumId} />
                <button type="submit" className="bg-blueGray-200 hover:bg-blue-700 text-felixblue-400 font-bold py-2 px-4 rounded mt-1">
                  Checkout
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

        </>
        
    );
  }
  
  
  