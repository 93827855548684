import React from 'react';
import { Link } from "react-router-dom";

function BlogCard({ imageUrl, title, description, alt, path }) {
    const blogLink = `blog/${path}`
  return (
    <div className="w-10/12 md:w-6/12 lg:w-4/12 px-4 md:px-4 mr-auto ml-auto -mt-32">
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-felixblue-400">
      <Link to={blogLink}>
      <img
          alt={alt}
          src={imageUrl}
          className="w-full align-middle rounded-t-lg"
        />
      </Link>
      <Link to={blogLink}>
      <blockquote className="relative p-8 mb-4">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 583 95"
            className="absolute left-0 w-full block h-95-px -top-94-px"
          >
            <polygon
              points="-30,95 583,95 583,65"
              className="text-felixblue-400 fill-current"
            ></polygon>
          </svg>
          <h2 className="text-xl font-bold text-white">{title}</h2>
          <p className="text-md font-light mt-2 text-white">{description}</p>
        </blockquote>
      </Link>
        
      </div>
    </div>
  );
}

export default BlogCard;