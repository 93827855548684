import React from "react";

export default function Privacy() {
  return (
    <>
      <main>
      <img
                        alt="..."
                        src={require("assets/img/privacy-01.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-02.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-03.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-04.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-05.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-06.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-07.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-08.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-09.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-10.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-11.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-12.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/privacy-13.png").default}
                        className="w-full"
                      />
      </main>
    </>
  );
}


// import React from "react";
// import pdfFile from 'assets/pdfs/privacy.pdf'; //import the PDF

// export default function Privacy() {
//   return (
//     <>
//       <main>
//         <section className="relative w-full h-full py-20 min-h-screen">
//           <div
//             className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"
//             style={{
//               backgroundImage:
//                 "url(" + require("assets/img/felix_auth_bg.png").default + ")",
//             }}
//           ></div>
          
//           <>
//           <div className="container mx-auto px-4 h-full">
//               <div className="flex items-center justify-center h-full">
//                 <div className="w-full px-4">
//                   <div className="flex items-center justify-center relative">
//                     <div className="w-full flex justify-center">
//                       <object data={pdfFile} type="application/pdf" width="60%" height="600px">
//                         <p>This browser does not support PDFs. Please download the PDF to view it: 
//                         <a href={pdfFile}>Download PDF</a></p>
//                       </object>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </>
//         </section>
//       </main>
//     </>
//   );
// }
