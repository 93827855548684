const SLACK_CLIENT_ID='5174432718086.5186590225317'
// const ADMIN_SCOPE='chat:write,channels:read,groups:read,im:read,mpim:read'
// const BOT_SCOPE='channels:history,users:read,users:read.email,chat:write,commands,channels:read,groups:read,im:read,mpim:read'
const ADMIN_SCOPE='chat:write'
const BOT_SCOPE='users:read,team:read,chat:write,commands'

const oauthRedirect = 'https://api.getfelix.app/slack/oauth_redirect';
export const apiKey = "ADJHBVIUF92346834AGDJDBFYS7283501";
export const currentUrl = 'https://www.getfelix.app'
export const workspaceInstallHref = `https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=${BOT_SCOPE}&user_scope=${ADMIN_SCOPE}&redirect_uri=${oauthRedirect}`;
// export const userScopesInstalHref = `https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&user_scope=${ADMIN_SCOPE}&redirect_uri=${oauthRedirect}`;