import React from "react";

// components

import CardSettingsStat from "components/Cards/CardSettingsStat.js";

export default function HeaderStats({
  calls,
  users,
  subscriptionType,
  billingStatus
}) {
  return (
    <>
      {/* Header */}
      <div className="relative bg-blueGray-50 md:pt-32 pb-32 pt-2">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="mb-2">
              <h3 className="text-3xl font-semibold text-blueGray-700">
                Team Stats
              </h3>
            </div>
            
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardSettingsStat
                  statTitle={calls}
                  statSubtitle="Calls this Month"
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardSettingsStat
                  statSubtitle="Users this Month"
                  statTitle={users}
                  statIconName="fas fa-users"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardSettingsStat
                  statSubtitle="Subscription"
                  statTitle={subscriptionType}
                  statIconName="fas fa-users"
                  statIconColor="bg-pink-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardSettingsStat
                  statSubtitle="Billing Status"
                  statTitle={billingStatus}
                  statIconName="fas fa-percent"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
