import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { useParams } from "react-router-dom";
import Markdown from "react-remarkable"
import { blog_contents } from "utilities/blogContent";


export default function BlogArticle() {
  const { articlePath } = useParams();

  const thisArticle = blog_contents.find((content) => content.path === articlePath)


  return (
    <>
      <main>
        <IndexNavbar fixed />
        <section className="relative w-full h-full py min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/felix_auth_bg.png").default + ")",
            }}
          ></div>
          
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-10/12 px-4 pt-32">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-felixblue-200 border-0">
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0 bg-white">
                    <img
                      alt={thisArticle.description}
                      src={thisArticle.imageUrl}
                      className="w-1/2 align-middle rounded-t-lg mx-auto" // Added classes and styles here
                    />
                    <h2 className="text-xl font-bold text-black">{thisArticle.title}</h2>
                    <Markdown options={{ html: true }}>
                      {thisArticle.content}
                    </Markdown>

                  </div>
                </div>
              </div>
            </div>
          </div>
          
          
        </section>
      </main>
    </>
  );
}
