import React from "react";

export default function TOS() {
  return (
    <>
      <main>
      <img
                        alt="..."
                        src={require("assets/img/tos-1.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/tos-2.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/tos-3.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/tos-4.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/tos-5.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/tos-6.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/tos-7.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/tos-8.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/tos-9.png").default}
                        className="w-full"
                      />
                      <img
                        alt="..."
                        src={require("assets/img/tos-10.png").default}
                        className="w-full"
                      />
      </main>
    </>
  );
}


// import React from "react";
// import pdfFile from 'assets/pdfs/tos.pdf'; //import the PDF

// export default function TOS() {
//   return (
//     <>
//       <main>
//         <section className="relative w-full h-full py-20 min-h-screen">
//           <div
//             className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"
//             style={{
//               backgroundImage:
//                 "url(" + require("assets/img/felix_auth_bg.png").default + ")",
//             }}
//           ></div>
          
//           <>
//           <div className="container mx-auto px-4 h-full">
//               <div className="flex items-center justify-center h-full">
//                 <div className="w-full px-4">
//                   <div className="flex items-center justify-center relative">
//                     <div className="w-full flex justify-center">
//                       <object data={pdfFile} type="application/pdf" width="60%" height="600px">
//                         <p>This browser does not support PDFs. Please download the PDF to view it: 
//                         <a href={pdfFile}>Download PDF</a></p>
//                       </object>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </>
//         </section>
//         <img
//             alt="slack"
//             className="w-8"
//             src={require("assets/img/felix_image_content.png").default}
//           />
//       </main>
//     </>
//   );
// }
