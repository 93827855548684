import { apiKey } from "../../utilities/helperFunctions.js"

export default function ManageBilling(
  {stripeCustomerId}
) {
  return (
    <div className="w-4/12 px-4">
      <body>
        <form method="POST" action={`https://api.getfelix.app/stripe/create-customer-portal-session?customerId=${stripeCustomerId}&apiKey=${apiKey}`}>
          <div className="inline-flex items-center px-2 border-2 rounded p-2 border-solid border-stripe-900 bg-white">

            <img
              alt="Felix"
              className="w-8"
              src={require("assets/img/stripe_logo.png").default}
            />
            <div className="ml-2">
              <button type="submit">
                  Manage billing
              </button>
            </div>  
          </div>  
        </form>
      </body>
    </div>
  );
}


