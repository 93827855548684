import React from "react";
import jwt_decode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import ManageBilling from "components/Stripe/ManageBilling.js";
import UpgradePlan from "components/Stripe/UpgradePlan.js";
import { currentUrl } from "../utilities/helperFunctions"
import axios from 'axios';
import { apiKey } from "../utilities/helperFunctions.js"

// components
import HeaderStats from "components/Headers/HeaderStats.js";

// fetchTeams function that would be used by the react-query hook
const fetchTeams = async (teamId, userId) => {
  try {
    const { data } = await axios.get(
      `https://api.getfelix.app/stripe/get-team-info?teamId=${teamId}&apiKey=${apiKey}&userId=${userId}`, 
      {
        headers: {
          'Content-Type': 'application/json'
          // You can add more headers here if needed
        }
      }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export default function Team() {
  // Slack consts
  const [authCode, setAuthCode] = React.useState(null);
  const [isAuthed, setIsAuthed] = React.useState(false);
  const [userProfPic, setUserProfPic] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [teamId, setTeamId] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [isBlocked, setIsBlocked] = React.useState(false);

  // Stripe consts
  const [planName, setPlanName] = React.useState("");
  const [stripePlanId, setStripePlanId] = React.useState(null);
  // const [stripeProductId, setStripeProductId] = React.useState("");
  // const [monthlyAllowedCalls, setMonthlyAllowedCalls] = React.useState(0);
  const [stripeCustomerId, setStripeCustomerId] = React.useState("");

  // Felix Usage
  const [usersThisMonth, setUsersThisMonth] = React.useState(0);
  const [callsThisMonth, setCallsThisMonth] = React.useState(0);


  const client_secret = 'cf0b7768c668eff2038c1f9c214216e9'
  const client_id = '5174432718086.5186590225317'
  const redirect_uri = `${currentUrl}/team`
  const history = useHistory();
 

  const setItemsFromJwt = (decoded) => {
    setIsAuthed(true);
    // console.log(decoded)
    // setUserProfPic(decoded.picture);
    setTeamId(decoded['https://slack.com/team_id'])
    // setTeamProfPic(decoded['https://slack.com/team_image_230'])
    setUserId(decoded['https://slack.com/user_id'])
    // setUserName(decoded['name'])
    // setTeamName(decoded['https://slack.com/team_name'])
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {
    if (teamId && userId) {
      const output = await fetchTeams(teamId, userId)
      if (!output) {
        // Output will be null if the get fails, which means they have never installed Felix
        setIsBlocked(true);
      }
      else if (output._doc) {
        setUsersThisMonth(output.users);
        setCallsThisMonth(output.calls);
        const stripeDetails = output._doc;
        setPlanName(stripeDetails.planName);
        setStripeCustomerId(stripeDetails.stripeCustomerId);
        setStripePlanId(stripeDetails.stripePlanId);
        // setStripeProductId(stripeDetails.stripeProductId);
        // setMonthlyAllowedCalls(stripeDetails.monthlyAllowedCalls);

        setUserProfPic(output.profileInfo.user.profile.image_192);
        // setTeamProfPic(decoded['https://slack.com/team_image_230'])
        setUserName(output.profileInfo.user.profile.display_name)
        // setTeamName(decoded['https://slack.com/team_name'])
      }
      
    }
  }, [teamId, userId])


  React.useEffect(() => {
    // Set the URL in state when the component mounts
    const authCode = window.location.href.split('?code=')[1]
    if (authCode) {
      setAuthCode(authCode)
    } else {
      const jwt = localStorage.getItem("felix_jwt");
      let isAuthed = false
      if (jwt) {
        const decodedJwt = jwt_decode(jwt);
        if (decodedJwt) {
          const curTime = Math.floor(Date.now() / 1000)
          if (decodedJwt.exp > curTime) {
            isAuthed = true;
            setItemsFromJwt(decodedJwt)
          }
        } 
      }
      if (!isAuthed) {
        if (window.location.href !== 'http://localhost:3000/team') {
          history.push('/auth/login');  // Redirect to login
        }
        
      }
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array means this effect runs once on mount

  React.useEffect(() => {
    if (authCode) {
      fetch('https://slack.com/api/openid.connect.token', {
        method: 'POST',
        body: new URLSearchParams({
          'client_secret': client_secret,
          'client_id': client_id,
          'redirect_uri': redirect_uri,
          'code': authCode,
        }),
      })
        .then(response => response.json())
        .then(data => {
          // Decode JWT
          const decoded = jwt_decode(data.id_token);
          localStorage.setItem("felix_jwt", JSON.stringify(data.id_token));
          setItemsFromJwt(decoded)
          history.push('/team');  // redirect to remove the code
        }
        )
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode]); // Runs when authCode changes

  const logout = () => {
    // Remove JWT from local storage
    localStorage.removeItem('felix_jwt');
    // Redirect to root directory
    history.push('/');
  };

  return (
    <>
      <div className="relative bg-blueGray-50">
        <div className="relative bg-blueGray-50">

        <div className="container mx-auto px-4 pt-20">
          <div className="items-center flex flex-wrap flex-row"> 
            <div className="w-full md:w-12/12 ml-auto px-12 md:px-4 order-1 md:order-2">
              <div className="md:pr-12">
                <div className="text-blueGray-700 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blueGray-50">
                <img
                  alt="Felix"
                  className="w-8"
                  src={require("assets/img/felix_done.png").default}
                />
                </div>
                <h3 className="text-3xl font-semibold text-blueGray-700">
                  Felix.
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-blueGray-700">
                  Manage your teams account and billing
                </p>

                <p className="mt-4 text-lg leading-relaxed text-blueGray-700">
                  {planName ? `Current Felix subscription: ${planName}` : 'Free Trial'}
                </p>
                <ul className="list-none mt-6 inline-flex">
                  {/* <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 mr-10 uppercase rounded-full text-blueGray-700 bg-blueGray-50 mr-3">
                          <img src={teamProfPic} alt="Team Profile" className="rounded-full h-16 w-16" />
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-700">
                          {teamName}
                        </h4>
                      </div>
                    </div>
                  </li> */}
                  <li className="py-2  px-6">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-700 bg-blueGray-50 mr-3">
                          <img src={userProfPic} alt="User Profile" className="rounded-full h-16 w-16" />
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-700">
                          {userName}
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
        </div>

        <HeaderStats 
        calls={isAuthed ? callsThisMonth.toString() : 'NA'}
        users={isAuthed ? usersThisMonth.toString() : 'NA'}
        subscriptionType={isAuthed ? planName : ''}
        billingStatus={isAuthed ? 'Paid' : ''}
        />

        <div className="px-4 md:px-10 mx-auto w-full">
        <>
          <div className="flex flex-wrap">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          </div>
          </div>

          {isBlocked ? (
            <h1>
              Please install Felix 😊
            </h1>
          ) : isAuthed && stripePlanId && stripeCustomerId !== '' ? (
            <ManageBilling
              stripeCustomerId={stripeCustomerId}
            />
          ) : <UpgradePlan
                teamId={teamId}
              />
          }
          
          <div className="flex flex-wrap justify-between mt-4">
            <div className="w-4/12 px-4">
                <button
                  className="w-full text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-700"
                  onClick={logout}
                >
                <i className="fas fa-sign-out-alt mr-2 text-md text-blueGray-300"></i>
                Logout
              </button>
            </div>
            <div className="w-4/12 px-4">
              <a
                  href="mailto:team@getfelix.app"
                  className="w-full text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-700"
              >
                  <i className="fas fa-envelope mr-2 text-md text-blueGray-300"></i>
                  Need Support? Email Us
              </a>
            </div>
          </div>
        </>
        </div>
      </div>
    </>
  );
}
