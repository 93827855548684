import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-blueGray-200 pt-2 pb-2">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-2xl font-semibold">Features</h4>
              <div className="pb-4 flex">
                <div className="max-w-180-px mr-2">
                <a href="https://www.wikigpt3.com/tool/felix" target="_blank" rel="noreferrer"><img src="https://www.wikigpt3.com/images/share-rate/featured_badge.png" alt="Featured Badge"/></a>
                </div>
                <div className="max-w-180-px rounded-lg overflow-hidden  mr-2">
                  <a
                    href="https://www.producthunt.com/products/felix-3/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Product_Hunt_Logo.svg/500px-Product_Hunt_Logo.svg.png?20190418040435"
                      alt="Product Hunt Launch"
                      className="bg-white rounded-lg px-2 py-1"
                    />
                  </a>
                </div>
                <div className="max-w-180-px mr-2">
                <a href="https://www.wikiaitools.com/" target="_blank" rel="noreferrer"><img src="https://www.wikiaitools.com/images/share-rate/featured_badge.png" alt="Wiki AI Tools Badge"/></a>
                </div>

                <div>
                <a href="https://topai.tools/t/felix-app?ref=embed" target="_blank" rel="noreferrer">
                <img
              alt="Felix"
              src={require("assets/img/topai.png").default}
            />
                  </a>
                </div>          
              </div>
              

              
              

              <h4 className="text-2xl font-semibold">Disclaimer</h4>
              <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                Felix uses large language models (LLMs) provided by OpenAI to produce message recommendations. All LLM's, such as ChatGPT, have the potential to generate faulty or otherwise erroeneous responses. As such, Felix may produce inaccurate responses.
              </h5>
              {/* <div className="mt-6 lg:mb-0 mb-2">
                <button
                  className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-twitter"></i>
                </button>
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-facebook-square"></i>
                </button>
                <button
                  className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-dribbble"></i>
                </button>
                <button
                  className="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-github"></i>
                </button>
              </div> */}
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                    Useful Links
                  </span>
                  <ul className="list-unstyled">
                    <li className="text-blueGray-600">
                      <medium>Contact: team@getfelix.app</medium>
                    </li>
                    <li>
                    <Link to="/privacy" className="text-blueGray-600">
                      <medium>Privacy Policy</medium>
                    </Link>
                    </li>
                    <li>
                    <Link to="/support" className="text-blueGray-600">
                      <medium>Support</medium>
                    </Link>
                    </li>
                    <li>
                    <Link to="/tos" className="text-blueGray-600">
                      <medium>Terms of Service</medium>
                    </Link>
                    </li>
                    <li>
                    <Link to="/blog" className="text-blueGray-600">
                      <medium>Blog</medium>
                    </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}