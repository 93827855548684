/*eslint-disable*/
import React from "react";
import { workspaceInstallHref } from "utilities/helperFunctions"

import Navbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
  return (
    <>
      <Navbar directToBlog={true} />
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-32 sm:pt-0">
              <h1 className="font-semibold text-4xl text-blueGray-600">
                Felix.
              </h1>
              <h2 className="mt-4 text-lg leading-relaxed text-blueGray-500">
                Felix uses generative AI to help with your writing in Slack. Use Felix to set your context and preferred writing style, and Felix will do the rest.
              </h2>

              <div className="mt-12">
              <a
                  href={workspaceInstallHref}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-32 h-10 bg-slack-800 text-white text-xs font-bold uppercase px-2 py-2 rounded shadow hover:shadow-lg outline-none ease-linear transition-all duration-150 inline-flex items-center"
              >
                  <img
                      alt="slack"
                      className="w-8"
                      src={require("assets/img/Slack-mark.svg").default}
                  />
                  Install
              </a>

              </div>
            </div>
          </div>
        </div>

        <img
          className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
          src={require("assets/img/felix_decals.png").default}
          alt="..."
        />
      </section>

      <section className="flex items-center justify-center">
        <div>
          <h2 className="mt-4 text-xl leading-relaxed text-blueGray-500 mb-2">
            🎉 🎉 Learn how to use Felix 🎉 🎉
          </h2>
          <iframe width="640" height="347"  src="https://www.loom.com/embed/1ff52db0550e4ca39d1a297746f558ce?sid=07a840eb-90c1-4728-aed9-0aa13f61493c" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen className="rounded-lg shadow-lg"></iframe>
        </div>        
      </section>

      <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-felixblue-400">
                <img
                  alt="..."
                  src={require("assets/img/felix_screenshot.png").default}
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-felixblue-400 fill-current"
                    ></polygon>
                  </svg>
                  <h2 className="text-xl font-bold text-white">
                    Customize Felix For Each User
                  </h2>
                  <p className="text-md font-light mt-2 text-white">
                    Each user in your workspace can customize their Felix experience. Users can write custom prompts, pick a tone, fix spelling and grammar, and pick from fun characters.
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i class="fas fa-comments"></i>
                      </div>
                      <h2 className="text-xl mb-1 font-semibold">
                        Pick A Tone
                      </h2>
                      <p className="mb-4 text-blueGray-500">
                        Users can modify their messages to be more friendly, confident, or much more.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i class="fas fa-spell-check"></i>
                      </div>
                      <h2 className="text-xl mb-1 font-semibold">
                        Spelling & Grammar
                      </h2>
                      <p className="mb-4 text-blueGray-500">
                        Felix will automatically correct any spelling and grammar mistakes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-keyboard"></i>
                      </div>
                      <h2 className="text-xl mb-1 font-semibold">
                        Custom Prompts
                      </h2>
                      <p className="mb-4 text-blueGray-500">
                        Users can provide custom prompts for the AI model
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i class="fas fa-paper-plane"></i>
                      </div>
                      <h2 className="text-xl mb-1 font-semibold">
                        Message Previews
                      </h2>
                      <p className="mb-4 text-blueGray-500">
                        Felix will send a preview of your re-written message so you always have final say 👍
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto overflow-hidden pb-2">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i class="fab fa-slack"></i>
              </div>
              <h1 className="text-3xl mb-2 font-semibold leading-normal">
                Integrated directly into Slack
              </h1>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                <a>Felix is custom built for Slack, making it easy to use. simply start your message with</a>
                <a className="font-semibold"> /felix </a>
                <a> and start typing</a>
              </p>
              {/* <a
                href="https://www.creative-tim.com/learning-lab/tailwind/react/alerts/notus?ref=nr-index"
                target="_blank"
                className="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"
              >
                View All{" "}
                <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
              </a> */}
            </div>

            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
              <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                {/* <img
                  alt="..."
                  src={require("assets/img/component-btn.png").default}
                  className="w-full align-middle rounded absolute shadow-lg max-w-100-px z-3 left-145-px -top-29-px"
                /> */}
                <img
                  alt="..."
                  src={require("assets/img/custom_directions.png").default}
                  className="w-full align-middle rounded-lg absolute shadow-lg -top-160-px left-260-px max-w-210-px"
                />
                <img
                  alt="..."
                  src={require("assets/img/felix_options.png").default}
                  className="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px -top-225-px left-40-px z-2"
                />
                {/* <img
                  alt="..."
                  src={require("assets/img/component-info-2.png").default}
                  className="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
                /> */}
                <img
                  alt="..."
                  src={require("assets/img/slack_example.png").default}
                  className="w-full align-middle rounded absolute shadow-lg max-w-580-px -left-20-px top-110-px"
                />
                <img
                  alt="..."
                  src={require("assets/img/felix_characters.png").default}
                  className="w-full align-middle rounded absolute shadow-xl max-w-320-px left-165-px top-95-px"
                />
              </div>
            </div>
          </div>

          {/* <div className="flex flex-wrap items-center pt-32">
            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
              <div className="justify-center flex flex-wrap relative">
                <div className="my-4 w-full lg:w-6/12 px-4">
                  <a
                    href="https://www.creative-tim.com/learning-lab/tailwind/svelte/alerts/notus?ref=vtw-index"
                    target="_blank"
                  >
                    <div className="bg-red-600 shadow-lg rounded-lg text-center p-8">
                      <img
                        alt="..."
                        className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                        src={require("assets/img/felix_done.png").default}
                      />
                      <p className="text-lg text-white mt-4 font-semibold">
                        Svelte
                      </p>
                    </div>
                  </a>
                  <a
                    href="https://www.creative-tim.com/learning-lab/tailwind/react/alerts/notus?ref=vtw-index"
                    target="_blank"
                  >
                    <div className="bg-lightBlue-500 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img
                        alt="..."
                        className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                        src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/react.jpg"
                      />
                      <p className="text-lg text-white mt-4 font-semibold">
                        ReactJS
                      </p>
                    </div>
                  </a>
                  <a
                    href="https://www.creative-tim.com/learning-lab/tailwind/nextjs/alerts/notus?ref=vtw-index"
                    target="_blank"
                  >
                    <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img
                        alt="..."
                        className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                        src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/nextjs.jpg"
                      />
                      <p className="text-lg text-white mt-4 font-semibold">
                        NextJS
                      </p>
                    </div>
                  </a>
                </div>
                <div className="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                  <a
                    href="https://www.creative-tim.com/learning-lab/tailwind/js/alerts/notus?ref=vtw-index"
                    target="_blank"
                  >
                    <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-8">
                      <img
                        alt="..."
                        className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                        src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/js.png"
                      />
                      <p className="text-lg text-white mt-4 font-semibold">
                        JavaScript
                      </p>
                    </div>
                  </a>
                  <a
                    href="https://www.creative-tim.com/learning-lab/tailwind/angular/alerts/notus?ref=vtw-index"
                    target="_blank"
                  >
                    <div className="bg-red-700 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img
                        alt="..."
                        className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                        src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/angular.jpg"
                      />
                      <p className="text-lg text-white mt-4 font-semibold">
                        Angular
                      </p>
                    </div>
                  </a>
                  <a
                    href="https://www.creative-tim.com/learning-lab/tailwind/vue/alerts/notus?ref=vtw-index"
                    target="_blank"
                  >
                    <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img
                        alt="..."
                        className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                        src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/vue.jpg"
                      />
                      <p className="text-lg text-white mt-4 font-semibold">
                        Vue.js
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-drafting-compass text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Javascript Components
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                In order to create a great User Experience some components
                require JavaScript. In this way you can manipulate the elements
                on the page and give more options to your users.
              </p>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                We created a set of Components that are dynamic and come to help
                you.
              </p>
              <div className="block pb-6">
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Alerts
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Dropdowns
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Menus
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Modals
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Navbars
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Popovers
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Tabs
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Tooltips
                </span>
              </div>
              <a
                href="https://www.creative-tim.com/learning-lab/tailwind/react/alerts/notus?ref=nr-index"
                target="_blank"
                className="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"
              >
                View all{" "}
                <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
              </a>
            </div>
          </div> */}
        </div>

        <div className="container mx-auto px-4 pb-32 pt-20">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-file-alt text-xl"></i>
                </div>
                <h1 className="text-3xl font-semibold">
                  Built in Message Editing
                </h1>
                <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                  Check out how Felix works.
                </p>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                          <i class="fas fa-wrench"></i>
                        </span>
                      </div>
                      <div>
                        <h2 className="text-blueGray-500">
                          Each user sets up Felix to their liking 
                        </h2>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                          <i class="fas fa-phone"></i>
                        </span>
                      </div>
                      <div>
                        <h2 className="text-blueGray-500">
                          Prompt Felix with your message
                        </h2>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                          <i className="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Approve and send the output
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-xl"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExYTZmYWYzZmU1NGYxNDU5MmM2MGM4MjI4ZGM3OWY5ZDg0ZTEyMTE0MCZlcD12MV9pbnRlcm5hbF9naWZzX2dpZklkJmN0PWc/v20bmolmdUIvij6P0T/giphy.gif'}
              />
            </div>
          </div>
        </div>

        <div id="installDirections" className="container mx-auto overflow-hidden pb-2">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-2">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i class="fas fa-download"></i>
              </div>
              <h1 className="text-3xl mb-2 font-semibold leading-normal">
                Install to Slack with One Click
              </h1>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                <a>To install Felix you must be an Orgadmin</a>
              </p>
            </div>

            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-6">
              <div className="mt-4 mb-4 flex justify-center">  {/*Add flex and justify-center*/}
                <a
                  href={workspaceInstallHref}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-32 h-10 bg-slack-800 text-white text-lg font-bold uppercase px-6 py-4 rounded shadow hover:shadow-lg outline-none ease-linear transition-all duration-150 inline-flex items-center mx-auto" /* Increase size of button */
                >
                  <img
                    alt="slack"
                    className="w-12"
                    src={require("assets/img/Slack-mark.svg").default}
                  />
                  Install
                </a>
              </div>
            </div>
          </div>
        </div>


        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <h2 className="font-semibold text-4xl">Pricing for Slack Workspace</h2>
            <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
              Pay for Felix based on how much you use it!
            </p>
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-orange-500">
                  <i class="fas fa-unlock"></i>
                </div>
                <h6 className="text-xl font-semibold">Free Tier</h6>
                <p className="mt-2 mb-4 text-blueGray-500">
                  10 calls per month. Just enough to test it out!
                </p>
              </div>
            </div>
          </div>

          <div className="w-full md:w-4/12 px-4 text-center">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                  <i class="fas fa-running"></i>
                </div>
                <h6 className="text-xl font-semibold">Pro: $10 / mo</h6>
                <p className="mt-2 mb-4 text-blueGray-500">
                  50 calls per month across all users
                </p>
              </div>
            </div>
          </div>

          <div className="pt-6 w-full md:w-4/12 px-4 text-center">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                  <i class="fas fa-glass-cheers"></i>
                </div>
                <h6 className="text-xl font-semibold">Premium: $25 / mo</h6>
                <p className="mt-2 mb-4 text-blueGray-500">
                  200 calls per month. Make Felix a part of your team!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="pb-16 bg-blueGray-200 relative pt-32">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        
      </section>
      <Footer />
    </>
  );
}
