import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Auth from "layouts/Auth.js";
import Blog from "layouts/Blog";
import BlogArticle from "layouts/BlogArticle";
import Team from "layouts/Team.js";
import TOS from "layouts/TOS.js";
import Privacy from "layouts/Privacy.js";
import PaymentSuccess from "layouts/PaymentSuccess.js";
import InstallSuccess from "layouts/InstallSuccess.js";
import UserInstall from "layouts/UserInstall.js";
import Support from "layouts/Support.js";

// views without layouts
import Index from "views/Index.js";

import { QueryClient, QueryClientProvider } from 'react-query';


// Create a client
const queryClient = new QueryClient();

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Switch>
        {/* add routes with layouts */}
        <Route path="/team" component={Team} />
        <Route path="/blog/:articlePath" component={BlogArticle} />
        <Route path="/blog" component={Blog} />
        <Route path="/auth" component={Auth} />
        <Route path="/success" component={PaymentSuccess} />
        <Route path="/userinstall" component={UserInstall} />
        <Route path="/installsuccess" component={InstallSuccess} />
        <Route path="/tos" component={TOS} />
        <Route path="/tos.html"/>
        <Route path="/privacy" component={Privacy} />
        <Route path="/privacy.html"/>
        <Route path="/support" component={Support} />
        {/* add routes without layouts */}
        {/* <Route path="/landing" exact component={Landing} />
        <Route path="/profile" exact component={Profile} /> */}
        <Route path="/" exact component={Index} />
        {/* add redirect for first page */}
        <Redirect from="*" to="/" />
      </Switch>
      </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
