import React from "react";
import { workspaceInstallHref } from "utilities/helperFunctions"


export default function UserInstall() {
  
  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/felix_auth_bg.png").default + ")",
            }}
          >

          </div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-felixblue-400 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3">
                    <div className="mt-4 mb-4 justify-center">  {/*Add flex and justify-center*/}
                      <h3 className="text-white mb-4">
                        Welcome to Felix! Your orgadmin has already setup Felix. By Authenticating, you will be able to customize and use Felix!
                      </h3>
                      <a
                        href={workspaceInstallHref}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-32 h-10 bg-slack-800 text-white text-lg font-bold uppercase px-6 py-4 rounded shadow hover:shadow-lg outline-none ease-linear transition-all duration-150 inline-flex items-center mx-auto" /* Increase size of button */
                      >
                        <img
                          alt="slack"
                          className="w-12"
                          src={require("assets/img/Slack-mark.svg").default}
                        />
                        Authenticate
                      </a>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}