/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { workspaceInstallHref } from "../../utilities/helperFunctions"

export default function Navbar({ directToBlog }) {
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex items-center justify-between">
          {/* <Link
            to="/"
            className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
          >
            Felix
          </Link> */}
          <Link to='/'>
            <img
              alt="slack"
              className="w-8"
              src={require("assets/img/felix_done.png").default}
            />
          </Link>
          
          <div className="flex items-center bg-white shadow-none" id="example-navbar-warning">
            <ul className="flex flex-row list-none ml-auto">
            {/* <li className="flex items-center mr-3">
                <Link to={directToBlog ? '/blog' : '/'}>
                <button
                    className="w-32 h-10 bg-felixblue-500 text-white text-xs font-bold uppercase px-2 py-2 rounded shadow hover:shadow-lg outline-none ease-linear transition-all duration-150 inline-flex items-center"
                    type="button"
                >
                    {directToBlog ? 'Blog' : 'Home'}
                </button>
                </Link>
              </li> */}
              <li className="flex items-center mr-3">
                <Link to='/blog'>
                <button
                    className="w-32 h-10 bg-orange-500 text-white text-xs font-bold uppercase px-2 py-2 rounded shadow hover:shadow-lg outline-none ease-linear transition-all duration-150 inline-flex items-center"
                    type="button"
                >
                    Blog
                </button>
                </Link>
              </li>
              <li className="flex items-center mr-3">
                <Link to='/auth'>
                <button
                    className="w-32 h-10 bg-felixblue-500 text-white text-xs font-bold uppercase px-2 py-2 rounded shadow hover:shadow-lg outline-none ease-linear transition-all duration-150 inline-flex items-center"
                    type="button"
                >
                    <img
                        alt="slack"
                        className="w-5 h-5 m-2"
                        src={require("assets/img/felix_done.png").default}
                    />
                    Login
                </button>
                </Link>
              </li>
              <li className="flex items-center">
              <a
                  href={workspaceInstallHref}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-32 h-10 bg-slack-800 text-white text-xs font-bold uppercase px-2 py-2 rounded shadow hover:shadow-lg outline-none ease-linear transition-all duration-150 inline-flex items-center"
              >
                  <img
                      alt="slack"
                      className="w-8"
                      src={require("assets/img/Slack-mark.svg").default}
                  />
                  Install
              </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
